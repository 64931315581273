
export function modalAction(){
    const openBtns = document.querySelectorAll('[data-openModal]');

    if(openBtns.length > 0){
        for (let i = 0,len = openBtns.length; i < len; i++){
            let targetModal = document.querySelectorAll(openBtns[i].dataset.openmodal);

            openBtns[i].addEventListener('click',function () {
                for (let j = 0,len = targetModal.length; j < len; j++){
                    targetModal[j].classList.add('open-display');

                    setTimeout(function () {
                        targetModal[j].classList.add('open-opacity');
                    })
                }
            })
        }
    }


    const closeBtns = document.querySelectorAll('.modal-a_close');

    if (closeBtns.length > 0){
        for (let i = 0,len = closeBtns.length; i < len; i++){
            let targetModal = closeBtns[i].parentNode;

            closeBtns[i].addEventListener('click',function () {
                targetModal.classList.remove('open-opacity');

                setTimeout(function () {
                    targetModal.classList.remove('open-display');
                },500)
            })
        }
    }
}
