export function rankingTabSwitch() {

  // if (document.getElementById('top')) {
  //
  //   const rankingAllBtn = document.getElementById('rankingAllBtn'),
  //         rankingDietBtn = document.getElementById('rankingDietBtn'),
  //         rankingHealthBtn = document.getElementById('rankingHealthBtn'),
  //         categoryRanking = document.getElementById('categoryRanking');
  //
  //   rankingAllBtn.addEventListener('click', () => {
  //     categoryRanking.classList.remove('rankingDiet-open', 'rankingHealth-open');
  //     categoryRanking.classList.add('rankingAll-open');
  //   });
  //
  //   rankingDietBtn.addEventListener('click', () => {
  //     categoryRanking.classList.remove('rankingAll-open', 'rankingHealth-open');
  //     categoryRanking.classList.add('rankingDiet-open');
  //   });
  //
  //   rankingHealthBtn.addEventListener('click', () => {
  //     categoryRanking.classList.remove('rankingAll-open', 'rankingDiet-open');
  //     categoryRanking.classList.add('rankingHealth-open');
  //   });
  //
  // }
}
