/**
 * Viewport Setting
 * - ビューポート 振り分け設定
 */
export function viewportSetting() {

  const _ua = (u => ({
    Tablet:
      (u.indexOf("windows") !== -1 && u.indexOf("touch") !== -1 && u.indexOf("tablet pc") === -1)
      || u.indexOf("ipad") !== -1
      || (u.indexOf("android") !== -1 && u.indexOf("mobile") === -1)
      || (u.indexOf("firefox") !== -1 && u.indexOf("tablet") !== -1)
      || u.indexOf("kindle") !== -1
      || u.indexOf("silk") !== -1
      || u.indexOf("playbook") !== -1,
    Mobile: (u.indexOf("windows") !== -1 && u.indexOf("phone") !== -1)
      || u.indexOf("iphone") !== -1
      || u.indexOf("ipod") !== -1
      || (u.indexOf("android") !== -1 && u.indexOf("mobile") !== -1)
      || (u.indexOf("firefox") !== -1 && u.indexOf("mobile") !== -1)
      || u.indexOf("blackberry") !== -1
  }))(window.navigator.userAgent.toLowerCase());

  const metalist = document.getElementsByTagName('meta');

  for (let metaElements of metalist) {
    const name = metaElements.getAttribute('name');
    if (name && name.toLowerCase() === 'viewport') {
      if (_ua.Mobile) {
        metaElements.setAttribute('content', 'width=device-width, initial-scale=1');
      } else if (_ua.Tablet) {
        metaElements.setAttribute('content', 'width=1000');
      } else {
        metaElements.setAttribute('content', 'width=device-width');
      }
      break;
    }
  }

}