export function itemSearchMenuOpen() {

  // if (document.getElementById("searchItemMenu")) {
  //
  //   const searchItemMenu = document.getElementById('searchItemMenu');
  //   const searchItemMenuBtn = document.querySelector('#headGlobalSubNav ul li.search-btn');
  //   const searchItemMenuBg = document.getElementById('searchItemMenuBg');
  //
  //   const switchClassOpen = () => {
  //     searchItemMenu.classList.toggle('open');
  //     searchItemMenuBg.classList.toggle('open');
  //   };
  //
  //   searchItemMenuBtn.addEventListener('click', switchClassOpen, { passive:true });
  //   searchItemMenuBg.addEventListener('click', switchClassOpen, { passive:true });
  //
  // }
}
