import Swiper from "./../modules/swiper";

export function swiperSetting() {

  const time = new Date().getTime();

  // 各トップページのみ
  if (
    document.getElementById('top') ||
    document.getElementById('healthy-food') ||
    document.getElementById('kc-cosme')
  ) {
    /**
     * Swiper JS
     * - メインスライダー（PC）
     */
    let swiperMainPC_Parm = {
      loop: true,
      loopedSlides: 6,
      slidesPerView: 'auto',
      watchSlidesVisibility: true,
      speed: 700,
      centeredSlides: true,
      DOMAnimation: true,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next, .swiper-my-pagination_arrow-right',
        prevEl: '.swiper-button-prev, .swiper-my-pagination_arrow-left',
      },
      pagination: {
        el: '.swiper-my-pagination',
        clickable: true
      }
    };
    //健康食品TOPではpaginationを画像に
    if ( document.getElementById('healthy-food') ) {
      swiperMainPC_Parm.pagination.renderBullet = function(index, className) {
        return '<span class="' + className + '">' + '<img src="/assets/images_pc/healthy-food/main_slide-icon_0' + (index + 1) + '.png?' + `${time}` + '￿">' + '</span>';
      }
    }
    //化粧品ではフェードに
    if ( document.getElementById('kc-cosme') ) {
      swiperMainPC_Parm.effect = 'fade';
      swiperMainPC_Parm.speed = 1200;
    }

    const swiperMainPC = new Swiper('.swiper-container_PC', swiperMainPC_Parm);

    /**
     * Swiper JS
     * - メインスライダー（SP）
     */
    let swiperMainSP_Parm = {
      loop: true,
      slidesPerView: 1,
      watchSlidesVisibility: true,
      speed: 700,
      centeredSlides: true,
      DOMAnimation: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: '.swiper-button-next, .swiper-my-pagination_arrow-right',
        prevEl: '.swiper-button-prev, .swiper-my-pagination_arrow-left',
      },
      pagination: {
        el: '.swiper-my-pagination',
        clickable: true,
      },
    }
    //化粧品ではフェードに
    if ( document.getElementById('kc-cosme') ) {
      swiperMainSP_Parm.effect = 'fade';
      swiperMainPC_Parm.speed = 1200;
    }
    const swiperMainSP = new Swiper('.swiper-container_SP', swiperMainSP_Parm);


    swiperMainPC.constructor;
    swiperMainSP.constructor;



    /**
     * Swiper JS
     * - 新商品・限定商品スライダー（SP）
     */
    let swiperSubSP_parm = {
      loop: true,
      slidesPerView: 'auto',
      watchSlidesVisibility: true,
      spaceBetween: 9,
      speed: 700,
      centeredSlides: true,
      DOMAnimation: true,
      loopedSlides: 3,
      breakpoints: {
        320: {
          initialSlide: 1
        }
      }
    }

    //健康食品TOPではpaginationを画像に
    if ( document.getElementById('healthy-food') ) {
      swiperSubSP_parm.autoplay = {
        delay: 4500,
        disableOnInteraction: false
      };
    }

    const swiperSubSP = new Swiper('.slider-03', swiperSubSP_parm);



    if (document.getElementsByClassName('slider-03').length > 0){
      let mySwiperBool;

      window.addEventListener('DOMContentLoaded',function (){
        if( !window.matchMedia('(max-width: 750px)').matches ){
          swiperSubSP.destroy(false,true);
          mySwiperBool = false;
        }
        else if( window.matchMedia('(max-width: 750px)').matches ){
          swiperSubSP.init();
          mySwiperBool = true;
        }
      },false);

      window.addEventListener('resize',function (){
        if( !window.matchMedia('(max-width: 750px)').matches && mySwiperBool){
          swiperSubSP.destroy(false,true);
          mySwiperBool = false;
        }
        else if( window.matchMedia('(max-width: 750px)').matches && !(mySwiperBool)){
          swiperSubSP.init();
          mySwiperBool = true;

          setTimeout(function (){ swiperSubSP.update },300);
        }
      },false);
    }



    /**
     * Swiper JS
     * - メインスライダー（PC）
     */
    const rankingSlide = new Swiper('.topRanking_wrapper', {
      slidesPerView: 'auto',
      speed: 700,
      wrapperClass: 'topRanking_list',
      slideClass: 'topRanking_list_item',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });


    /**
     * Swiper JS
     * - スライダーにマウスホバーした時にスライドを停止
     */
    const stopSwiperSlide = () => {
      if (document.getElementById("mainSlider_PC")) {
        const sliderContainer = document.querySelector('#mainSlider_PC .swiper-container_PC').swiper;
        const slide03 = document.querySelectorAll('#mainSlider_PC .container .swiper-container_PC .swiper-wrapper .swiper-slide');

        for (let i = 0; i < slide03.length; i++) {
          slide03[i].addEventListener('mouseenter', () => { sliderContainer.autoplay.stop(); });
          slide03[i].addEventListener('mouseleave', () => { sliderContainer.autoplay.start(); });
        }
      }
    };

    if ( window.matchMedia('(max-width: 750px)').matches ){
      rankingSlide.constructor;
    }

    stopSwiperSlide();
  }


  // モリちゃん一家のドタバタ劇場（モリちゃん漫画）のみ
  if (document.getElementById("bulletin")) {

    /**
     * Swiper JS
     * - モリちゃん漫画スライダー（レスポンシブ）
     */
    const bulletinSlider = new Swiper(".bulletin-slider", {
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 20,
      grabCursor: true,
      initialSlide: 0,
      navigation: {
        nextEl: ".bulletin-swiper-button-next",
        prevEl: ".bulletin-swiper-button-prev"
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      breakpoints: {
        750: {
          spaceBetween: 10
        }
      }
    });

    bulletinSlider.constructor;

    window.addEventListener('DOMContentLoaded',function (){
      bulletinSlider.slideTo(0);
    })

  }

}