export function beautyRecipe_accordion(){
  const recipeMessage = document.getElementsByClassName('beautyRecipeSingle_message')[0];
  const recipeMessageToggle = document.getElementById('recipeMessageToggle');

  if (recipeMessage){
    recipeMessageToggle.addEventListener('click',function (){
      if ( !recipeMessage.classList.contains('open') ){
        recipeMessage.classList.add('open');
        recipeMessage.style.setProperty('height',recipeMessage.scrollHeight+'px')
        recipeMessageToggle.classList.add('open');
        recipeMessageToggle.innerText = '閉じる';
      }
      else {
        recipeMessage.classList.remove('open');
        recipeMessage.style.setProperty('height',null)
        recipeMessageToggle.classList.remove('open');
        recipeMessageToggle.innerText = '続きを読む';
      }
    })
  }
}