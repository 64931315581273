/**
 * よくある質問
 */
export function faqScript() {

  /**
   * - ページ偏移時 可視範囲を上部に移動
   */
  function faqHrefMoveTop() {
    if (document.getElementById("content-frame")) {
      const faqIframe = document.getElementById('content-frame');
      const movePageTop = () => window.scrollTo(0, 0);
      faqIframe.addEventListener('load', movePageTop, { passive: true });
    }
  }


  /**
   * - 商品について アコーディオンリスト 展開
   */
  function faqAccordion() {
    if (document.querySelector('.faq-accordion-list')) {
      const button = document.querySelectorAll('.faq-accordion-list li dl dt span');
      for (let buttonElement of button) {
        buttonElement.addEventListener('click', () => {
          const parentDt = buttonElement.parentElement,
                parentDl = parentDt.parentElement,
                parentLi = parentDl.parentElement;
          parentLi.classList.toggle('js-open');
          postHeight(); // 外部JS実行
        }, {});
      }
    }
  }


  /**
   * - ブラウザ幅リサイズ時 コンテンツ高さ 反映
   */
  function faqAtTheTimeOfResize() {
    if (document.getElementById("faqContents")) {
      addEventListener('resize', () => {
        setTimeout(postHeight, 1000);
      }, {});
    }
  }


  faqHrefMoveTop();
  faqAccordion();
  faqAtTheTimeOfResize();


}
