import $ from 'jquery';
import 'slick-carousel';

 export function rankingSlider() {
    $('.rankingCat').slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        asNavFor: '.rankingSlide',
        dots: false,
        arrows: false,
        focusOnSelect: true,
        variableWidth: true,
        infinite: false,
        centerMode: false,
        draggable: false,
        swipe: false,
        responsive: [
            {
                breakpoint: 750,
                settings: {
                    swipe: true,
                    draggable: true,
                    infinite: true,
                    centerMode: true,
                    slidesToShow: 1,
                }
            }
        ],
    });
    $('.rankingSlide').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.rankingCat',
        draggable: false,
        responsive: [
            {
                breakpoint: 750,
                settings: {
                    draggable: true,
                }
            }
        ],
    });
}

