export function globalNavFollow() {

  // function globalNavFollowAnimetion() {
  //
  //   const header = document.getElementsByTagName("header");
  //   const globalNav = document.getElementById("headGlobalSubNav");
  //   const getScrollAmout = () => document.documentElement.scrollTop || document.body.scrollTop;
  //
  //   if (document.getElementById("top")) {
  //     if (getScrollAmout() > 108) {
  //       header[0].style.marginTop = "120px";
  //       globalNav.classList.add("js-fixed-global-nav");
  //     } else {
  //       header[0].style.marginTop = "60px";
  //       globalNav.classList.remove("js-fixed-global-nav");
  //     }
  //   } else {
  //     if (getScrollAmout() > 1) {
  //       header[0].style.marginTop = "120px";
  //       globalNav.classList.add("js-fixed-global-nav");
  //     } else {
  //       header[0].style.marginTop = "60px";
  //       globalNav.classList.remove("js-fixed-global-nav");
  //     }
  //   }
  // }
  //
  // function globalNavAnimationRun() {
  //   let ticking = false;
  //   if (!ticking) {
  //     window.requestAnimationFrame(() => {
  //       globalNavFollowAnimetion();
  //       ticking = false;
  //     });
  //     ticking = true;
  //   }
  // }
  //
  // if (!window.matchMedia("(max-width: 750px)").matches) {
  //   window.addEventListener("scroll", globalNavAnimationRun, { passive: true });
  // }

}
