export function bulletinTabContents() {

  if (document.getElementById('TabContents')) {
    bulletinTabContentsPC();
    bulletinTabContentsSP();

    addEventListener('resize', () => {
      setTimeout(bulletinTabContentsPC, 1000);
      setTimeout(bulletinTabContentsSP, 1000);
    }, {});
  }

  // パソコン表示
  function bulletinTabContentsPC() {
    if (matchMedia('(min-width: 750px)').matches) {

      const tabMenuListPC = document.querySelectorAll('#TabMenuForPC ul li');
      const tabContent = document.querySelectorAll('#TabContentsWrap .tab-content');
      const tabLinkList = document.querySelectorAll('#tabLink > li');
      const tabChildLinkList = document.querySelectorAll('#tabLink > li > ul > li');

      /**
       * 現在のタブの番号を参照してコンテンツを表示
       * @param num
       */
      function changeTabContent(num = 0) {
        for (const tabContentElm of tabContent) {
          tabContentElm.style.display = "none";
        }
        tabContent[num].style.display = "block";
      }

      /**
       * 現在のタブの番号を参照してタブを0.3透過
       * @param num
       */
      function opacityTabList(num = 0) {
        for (const tabMenuList of tabMenuListPC) {
          tabMenuList.style.opacity = "0.3";
        }
        tabMenuListPC[num].style.opacity = "1";
      }

      /**
       * クリックしたタブを表示
       */
      for (const listElm of tabMenuListPC) {
        listElm.addEventListener('click', () => {
          const elements = [].slice.call(tabMenuListPC);
          const clickNum = elements.indexOf(listElm);
          opacityTabList(clickNum);
          changeTabContent(clickNum);
        })
      }

      /**
       * クリックしたリンクのタブを表示
       */
      for (const linkElm of tabLinkList) {
        linkElm.addEventListener('click', () => {
          const elements = [].slice.call(tabLinkList);
          const clickNum = elements.indexOf(linkElm);
          opacityTabList(clickNum);
          changeTabContent(clickNum);
        }, true)
      }

      /**
       * クリックした子リンクのタブを表示
       */
      for (const childlinkElm of tabChildLinkList) {
        childlinkElm.addEventListener('click', () => {
          const parentElm = childlinkElm.parentNode;
          const parentOfParentElm = parentElm.parentNode;
          const elements = [].slice.call(tabLinkList);
          const clickNum = elements.indexOf(parentOfParentElm);
          opacityTabList(clickNum);
          changeTabContent(clickNum);
        }, true)
      }

      opacityTabList();
      changeTabContent();

    }
  }

  // スマートフォン表示
  function bulletinTabContentsSP() {
    if (matchMedia('(max-width: 750px)').matches) {
      const tabContent = document.querySelectorAll('#TabContentsWrap .tab-content');
      for (const tabContentElm of tabContent) {
        tabContentElm.style.display = "block";
      }
    }
  }

}
