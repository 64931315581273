export function smoothScroll() {
  if (document.getElementById('mainHeader')) {
    window.addEventListener('DOMContentLoaded',function (){
      setTimeout(function (){
        let headH;

        let headMainH = document.getElementById('mainHeader').offsetHeight;
        let headNavH = document.getElementById('headGlobalSubNav').offsetHeight;
        let headNavCosmeH = document.getElementById('headGlobalSubNav-cosme').offsetHeight;
        let headNavAcds = document.getElementsByClassName('header_item-menu');

        headH = headMainH + headNavH + headNavCosmeH;

        for (let i = 0,len = headNavAcds.length; i < len; i++){
          headNavAcds[i].style.setProperty('top',headH+'px');
        }

        const smoothLink = (headH = 0) => {

          if (typeof headH === 'undefined') headH = 0;

          const interval = 10;
          const divisor = 8;
          const range = (divisor / 2) + 1;
          const links = document.querySelectorAll('a[href^="#"]');

          for (let i = 0,len = links.length; i < len; i++) {

            links[i].addEventListener('click', function(e) {

              e.preventDefault();

              let toY;
              let nowY = window.pageYOffset;
              const targetElm = links[i];
              const href = targetElm.getAttribute("href");
              const target = document.querySelector(href);
              const targetRect = target.getBoundingClientRect();
              const targetY = targetRect.top + nowY - headH;

              (function doScroll() {
                toY = nowY + Math.round((targetY - nowY) / divisor);
                window.scrollTo(0, toY);
                nowY = toY;
                if (document.body.clientHeight - window.innerHeight < toY) {
                  window.scrollTo(0, document.body.clientHeight);
                  return;
                }
                if (toY >= targetY + range || toY <= targetY - range) {
                  window.setTimeout(doScroll, interval);
                } else {
                  window.scrollTo(0, targetY);
                }
              })();

            });
          }
        };

        smoothLink(headH);
      },300)
    })
  }
}
