export function slideMenuOpen() {

  // if (document.getElementById("menuBtn")) {
  //
  //   const menuBtn = document.getElementById("menuBtn");
  //   const menuCloseBtn = document.getElementById("menuCloseBtn");
  //   const slideMenu = document.getElementById("slideMenu");
  //   const body = document.body;
  //
  //   menuBtn.addEventListener('click', () => {
  //     slideMenu.style.left = '0';
  //     body.style.overflow = "hidden";
  //     body.style.height = "100%";
  //   });
  //
  //   menuCloseBtn.addEventListener('click', () => {
  //     slideMenu.style.left = '110%';
  //     body.style.overflow = "visible";
  //     body.style.height = "auto";
  //   });
  //
  // }
}
