import "core-js";
import { viewportSetting } from "./modules/viewportSetting";
import { swiperSetting } from "./modules/swiperSetting";
import { scrollToTop } from "./modules/scrollToTop";
import { smoothScroll } from "./modules/smoothScroll";
import { rankingTabSwitch } from "./modules/rankingTabSwitch";
import { globalNavFollow } from "./modules/globalNavFollow";
import { itemSearchMenuOpen } from "./modules/itemSearchMenuOpen";
import { faqScript } from "./modules/faq";
import { slideMenuOpen } from "./modules/slideMenuOpen";
import { bulletinTabContents } from "./modules/bulletinTabContents";
import { rankingSlider } from "./modules/rankingSlider";
import { modalAction } from "./modules/modalOpen";
import { beautyRecipe_accordion } from "./modules/beautyRecipe_accordion";
import $ from "jquery";

/**
 * マイページ
 * - 購入履歴 展開
 */
function orderHistoryOpen() {
  if (document.getElementById("purchaseHistoryBtn")) {
    const moreHistoryBtn = document.getElementById("purchaseHistoryBtn");

    const toggleClassAndName = () => {
      const list = document.getElementById("purchaseHistoryItemList"),
        check = list.classList.contains("open");
      list.classList.toggle("open");
      moreHistoryBtn.innerText = check ? "もっと見る" : "閉じる";
    };

    moreHistoryBtn.addEventListener("click", toggleClassAndName, {
      passive: true
    });
  }
}

/**
 * ヘッダー
 * - 商品メニュー
 */

var acco_state = 0; // 展開の状態 0=全て閉,1=どれか開

function resetAcdPosition() {
  let headH;

  let headMainH = document.getElementById('mainHeader').offsetHeight;
  let headNavH = document.getElementById('headGlobalSubNav').offsetHeight;
  let headNavCosmeH = document.getElementById('headGlobalSubNav-cosme').offsetHeight;

  let headNavAcds = document.getElementsByClassName('header_item-menu');

  headH = headMainH + headNavH + headNavCosmeH;

  for (let i = 0,len = headNavAcds.length; i < len; i++){
    headNavAcds[i].style.setProperty('top',headH+'px');
  }
}

// 商品メニューの展開を閉じる
function allClose(closeTgts) {
  const allCatBtn = document.getElementsByClassName("accordion-menu_btn");
  const allCatAcco = document.getElementsByClassName("cat-acco");
  const allNav = document.getElementsByClassName("header-nav_acco-btn");

  // 展開を閉じる
  for (let k = 0, kLen = closeTgts.length; k < kLen; k++) {
    closeTgts[k].style.height = closeTgts[k].clientHeight + "px";
    closeTgts[k].classList.remove("header_item-menu_open");
  }

  for (let l = 0, lLen = closeTgts.length; l < lLen; l++) {
    closeTgts[l].style.height = null;
  }

  // カテゴリの展開を閉じる
  window.setTimeout(function() {
    for (let m = 0, mLen = allCatBtn.length; m < mLen; m++) {
      allCatBtn[m].classList.remove("open");
    }

    for (let n = 0, nLen = allCatAcco.length; n < nLen; n++) {
      allCatAcco[n].style.height = null;
    }
  }, 300);

  for (let o = 0, oLen = allNav.length; o < oLen; o++) {
    allNav[o].classList.remove("open");
  }
}

function headerNavAccordion() {
  "use strict";

  const top_acco_btn = document.querySelectorAll(".header-nav_acco-btn");
  const bg_cover = document.getElementById("searchItemMenuBg");
  const body = document.body;

  for (let i = 0, iLen = top_acco_btn.length; i < iLen; i++) {
    top_acco_btn[i].addEventListener("click", function() {
      resetAcdPosition();

      let accoBodyID = this.dataset.targetAccoId;
      let accoBody = document.getElementById(accoBodyID);
      let allAcco = document.querySelectorAll(
        ".header_item-menu:not(#" + accoBodyID + ")"
      );

      function acco_open() {
        accoBody.style.height = accoBody.scrollHeight + "px";
        window.setTimeout(function() {
          accoBody.style.height = "auto";
        }, 300);
        window.setTimeout(function() {
          accoBody.classList.add("header_item-menu_open");
        }, 300);
      }

      if (accoBody.style.height) {
        accoBody.style.height = accoBody.scrollHeight + "px";
        accoBody.classList.remove("header_item-menu_open");
        window.setTimeout(function() {
          accoBody.style.height = null;
        });

        this.classList.remove("open");

        acco_state = 0;

        bg_cover.classList.remove("open");
        body.style.overflow = null;
        body.style.height = "auto";
      } else {
        if (acco_state === 1) {
          allClose(allAcco);

          window.setTimeout(function() {
            acco_open();
          }, 400);
        } else {
          acco_open();
        }

        this.classList.add("open");

        acco_state = 1;

        bg_cover.classList.add("open");
        body.style.overflow = "hidden";
        body.style.height = "100%";
      }
    });
  }

  bg_cover.addEventListener("click", function() {
    let allAcco = document.getElementsByClassName("header_item-menu");
    allClose(allAcco);

    acco_state = 0;
    bg_cover.classList.remove("open");
    body.style.overflow = null;
    body.style.height = "auto";
  });
}

// ヘッダー商品メニュー カテゴリ詳細
function headerNavAccordionCategory() {
  const category_acco_btn = document.querySelectorAll(".accordion-menu_btn");

  for (let i = 0, iLen = category_acco_btn.length; i < iLen; i++) {
    category_acco_btn[i].addEventListener("click", function() {
      var accoBodyID = this.dataset.targetAccoId;
      var accoBody = document.getElementById(accoBodyID);

      if (accoBody.style.height) {
        accoBody.style.height = null;
      } else {
        accoBody.style.height = accoBody.scrollHeight + "px";
      }

      this.classList.toggle("open");
    });
  }
}

/**
 * ヘッダー
 * - 化粧品 商品一覧 SP 展開
 */

function headerCosmeItems_switch() {
  let switchElm = document.getElementById('headerCosmeItems-switch');


  if (
    switchElm &&
    window.matchMedia('(max-width: 750px)').matches
  ) {
    switchElm.addEventListener('click', function () {
      switchElm.classList.toggle('open-itemList');
    });
  }

}

/**
 * ヘッダー バーガーメニュー
 */
function headerSlideMenu() {
  const slideMenuSwitch = document.getElementById("menuBtn");
  const slideMenuMain = document.getElementById("slideMenu");
  const slideMenuCloseBtn = document.getElementById("slideMenu_close-btn");
  const slideMenuAcco = document.getElementById("slideMenu_menu_acco");
  const bg_cover = document.getElementById("slideMenuBg");
  const body = document.body;

  if (slideMenuSwitch) {
    function slideMenuOpen() {
      slideMenuMain.style.right = "0";
      bg_cover.classList.add("open");
      body.style.overflowY = "hidden";
      body.style.height = "100%!important";
    }

    function slideMenuClose() {
      slideMenuMain.style.right = null;
      bg_cover.classList.remove("open");
      body.style.overflowY = null;
      body.style.height = "auto";
      window.setTimeout(function() {
        slideMenuAcco.style.height = null;
      }, 300);
    }

    slideMenuSwitch.addEventListener("click", function() {
      console.log('test');

      let allAcco = document.getElementsByClassName("header_item-menu");

      if (acco_state === 1) {
        allClose(allAcco);

        window.setTimeout(function() {
          slideMenuOpen();
        }, 400);
      } else {
        slideMenuOpen();
      }
    });

    slideMenuCloseBtn.addEventListener("click", function() {
      slideMenuClose();
    });

    bg_cover.addEventListener("click", function() {
      slideMenuClose();
    });
  }
}

/**
 * はじめてのお客さまへ
 * - お得な定期価格一覧展開
 */
function openPriceListBeginner() {
  if (document.getElementById("beginnerSec03Btn")) {
    const btn = document.getElementById("beginnerSec03Btn");

    btn.addEventListener("click", () => {
      const listwrap = document.getElementById("beginnerSec04");

      listwrap.classList.toggle("open-list");
    });
  }
}

/**
 * 定期購入コースのご案内
 * - お得な定期価格一覧展開
 */
function openPriceListRegularly() {
  if (document.getElementById("subscriptionsPriceListBtn")) {
    const button = document.getElementById("subscriptionsPriceListBtn");

    button.addEventListener("click", () => {
      const table = document.querySelector("#subscriptionsPriceList table");
      const check = table.classList.contains("open-list");

      table.classList.toggle("open-list");
      button.innerText = check ? "全てを表示する" : "閉じる";
    });
  }
}

/**
 * 定期購入 次回お休み・ご解約フォーム
 * - 解約フォーム 展開
 */
function toggleCancelBox() {
  if (document.getElementById("regularCancelBtn")) {
    const canceBtn = document.getElementById("regularCancelBtn");
    const cancelForm = document.getElementById("cancelArea");
    canceBtn.addEventListener("click", e => {
      e.preventDefault();
      cancelForm.classList.toggle("open");
      canceBtn.classList.toggle("open");
    });
  }
}

/**
 * モリちゃん一家のドタバタ劇場（モリちゃん漫画）
 * - バックナンバー 一覧展開
 */
function backnumberListOpen() {
  if (document.getElementById("bulletinMoreArrow")) {
    const arrowButton = document.getElementById("bulletinMoreArrow");
    const list = document.querySelector(".bulletin-backnumber");
    arrowButton.addEventListener("click", () => {
      list.classList.add("js-bulletin-backnumber-open");
      arrowButton.style.display = "none";
    });
  }
}

/**
 * モリちゃん一家のドタバタ劇場（モリちゃん漫画 Vol.005）
 * タブ切り替えコンテンツ タブ切り替え
 */
function tabChangeContentBulletin005() {
  if (document.getElementById("tabChangeContent")) {
    const buttonAnorexia = document.querySelector(
      "#tabChangeContent .tab-anorexia"
    );
    const buttonWaterDeficit = document.querySelector(
      "#tabChangeContent .tab-water-deficit"
    );
    const buttonColdness = document.querySelector(
      "#tabChangeContent .tab-coldness"
    );

    const anchorButtonAnorexia = document.querySelector(
      '.bulletin-table-of-contents ul:last-child > li > ul > li:first-of-type a[href="#tabChangeContent"]'
    );
    const anchorButtonWaterDeficit = document.querySelector(
      '.bulletin-table-of-contents ul:last-child > li > ul > li:nth-of-type(2) a[href="#tabChangeContent"]'
    );
    const anchorButtonColdness = document.querySelector(
      '.bulletin-table-of-contents ul:last-child > li > ul > li:last-of-type a[href="#tabChangeContent"]'
    );

    const changeClassTabList = target => {
      const contentsBox = document.getElementById("tabChangeContent");
      contentsBox.removeAttribute("class");
      contentsBox.classList.add(target, "BLOCK_01");
    };

    buttonAnorexia.addEventListener("click", () => {
      changeClassTabList("current-content-anorexia");
    });

    buttonWaterDeficit.addEventListener("click", () => {
      changeClassTabList("current-content-water-deficit");
    });

    buttonColdness.addEventListener("click", () => {
      changeClassTabList("current-content-coldness");
    });

    anchorButtonAnorexia.addEventListener("click", () => {
      changeClassTabList("current-content-water-deficit");
    });

    anchorButtonWaterDeficit.addEventListener("click", () => {
      changeClassTabList("current-content-anorexia");
    });

    anchorButtonColdness.addEventListener("click", () => {
      changeClassTabList("current-content-coldness");
    });
  }
}

/**
 * 全角英数字を半角英数字に変換
 * @param elm
 */
function toHalfWidth(elm) {
  const regex = /[Ａ-Ｚａ-ｚ０-９！-～]/g;
  return elm.replace(regex, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
}

/**
 * BMI計算
 * @param weight
 * @param height
 * @returns {number}
 */
function calcBodyMassIndex(weight, height) {
  const h = Number(toHalfWidth(height)) / 100;
  const w = Number(toHalfWidth(weight));
  return Math.floor((w / (h * h)) * 100) / 100;
}

/**
 * シボヘール 商品詳細
 * - BMI計算 出力
 */
function outputBodyMassIndex() {
  if (document.getElementById("calcBodyMassIndex")) {
    const button = document.querySelector("#calcBodyMassIndex button");

    const outputResultBox = () => {
      const weightValue = document.getElementById("weightValue").value;
      const heightValue = document.getElementById("heightValue").value;
      const resultBox = document.querySelector(
        "#calcBodyMassIndex .result div"
      );
      const result = calcBodyMassIndex(weightValue, heightValue);
      resultBox.textContent = `${result}`;
    };

    button.addEventListener("click", outputResultBox, { passive: true });
  }
}

/**
 * お友達紹介
 * - 「ご紹介の流れ」コンテンツ切り替え
 */
function MemberIntroduceTabChange() {
  if (document.getElementById("MemberIntroduceFlow")) {
    const MemberIntroduceFlow = document.getElementById("MemberIntroduceFlow");
    const tabWeb = document.querySelector(
      ".MemberIntroduceFlow-tab ul .tab-web"
    );
    const tabTel = document.querySelector(
      ".MemberIntroduceFlow-tab ul .tab-tel"
    );

    const addClassWeb = () => {
      MemberIntroduceFlow.removeAttribute("class");
      MemberIntroduceFlow.classList.add("current-web");
    };

    const addClassTel = () => {
      MemberIntroduceFlow.removeAttribute("class");
      MemberIntroduceFlow.classList.add("current-tel");
    };

    tabWeb.addEventListener("click", addClassWeb, { passive: false });
    tabTel.addEventListener("click", addClassTel, { passive: false });
  }
}

/**
 * ヒザ潤滑源テーピングスパッツ アコーディオン
 */
function accordionContentItem1500() {
  if (document.querySelectorAll(".item-1500-section-accordion")) {
    const content = document.querySelectorAll(".item-1500-section-accordion");
    for (const contentElement of content) {
      const title = contentElement.querySelector("h4");
      title.addEventListener("click", function() {
        contentElement.classList.toggle("js-on-accordion");
      });
    }
  }
}

/**
 * ヒザ潤滑源テーピングスパッツ モーダル
 */
function modalContentItem1500() {
  if (document.querySelector(".item-1500-section-modal")) {
    const content = document.querySelector(".item-1500-section-modal");
    const openButton = document.querySelectorAll(
      ".item-1500-modal-button span"
    );
    const closeButton = document.querySelector(
      ".item-1500-section-modal-button"
    );

    for (const openButtonElement of openButton) {
      openButtonElement.addEventListener("click", function() {
        content.classList.add("js-on-modal");
      });
    }

    closeButton.addEventListener("click", function() {
      content.classList.remove("js-on-modal");
    });
  }
}

/**
 * スライドメニュー内カート数
 */

function slideMenuCartNum() {
  const cartNumOrigin = document.getElementById("cartNum");
  const cartNumDisplay = document.getElementById("slideMenu_cartNum");

  function setCartNum() {
    cartNumDisplay.innerHTML = cartNumOrigin.innerHTML;
  }

  setCartNum();

  cartNumOrigin.style.setProperty("display", "none");
}

/**
 * お客様の声
 * - プレゼントの内容一覧展開
 */
function openVoiceRecruitment() {
  if (document.getElementById("voiceRecruitment_btn")) {
    const button = document.getElementById("voiceRecruitment_btn");

    button.addEventListener("click", () => {
      const table = document.querySelector("#voiceRecruitment");
      const check = table.classList.contains("inner_open");

      table.classList.toggle("inner_open");
    });
  }
}

/**
 * 配送方法
 * - 文言追加
 */

function deliveryMethod() {
  var urlParam01 = location.href.match("RegularChangeAllInput");
  if (urlParam01) {
    document.addEventListener("DOMContentLoaded", function() {
      if (document.getElementById("postDeliverType_0_label")) {
        if (document.getElementById("postDeliverType_1_label")) {
          if (
            document.getElementById("postDeliverType_0_label").style.display !=
            "none"
          ) {
            if (document.getElementById("postDeliverType_1").checked) {
              var size = getValue("itemSelectGoodsGroupDataSize");
              if (size == 0) {
                var postDeliverType = document.getElementById("postDeliverTypeTdSurround");
                var p = document.createElement("p");
                p.textContent = "「宅配便：送料400円（税別）」よりお安くお届けできる「メール便：送料100円（税別）」をお選びいただけます。なお『通常価格』の合計が4,500円（税別）以上で送料無料です。";
                p.style.color = "red";
                p.style.margin = "5px 0 0";
                postDeliverType.appendChild(p);
              } else {

              }
            } else {
              var size = getValue("itemSelectGoodsGroupDataSize");
              if (size == 0) {
                var postDeliverType = document.getElementById("postDeliverTypeTdSurround");
                var p = document.createElement("p");
                p.textContent = "「宅配便：送料400円（税別）」よりお安くお届けできる「メール便：送料100円（税別）」をお選びいただけます。なお『通常価格』の合計が4,500円（税別）以上で送料無料です。";
                p.style.color = "red";
                p.style.margin = "5px 0 0";
                postDeliverType.appendChild(p);
              } else {
                var postDeliverType = document.getElementById("postDeliverTypeTdSurround");
                var p = document.createElement("p");
                p.textContent = "※プレミアム定額コースは、送料無料でお届けいたします。ご選択の商品によっては宅配便でのお届けとなります。";
                p.style.color = "red";
                p.style.margin = "5px 0 0";
                postDeliverType.appendChild(p);
              }
            }
          } else {
            if (document.getElementById("postDeliverType_1").checked) {
              var size = getValue("itemSelectGoodsGroupDataSize");
              if (size == 0) {
                var postDeliverType = document.getElementById("postDeliverTypeTdSurround");
                var p = document.createElement("p");
                p.textContent = "「宅配便：送料400円（税別）」よりお安くお届けできる「メール便：送料100円（税別）」をお選びいただけます。なお『通常価格』の合計が4,500円（税別）以上で送料無料です。";
                p.style.color = "red";
                p.style.margin = "5px 0 0";
                postDeliverType.appendChild(p);
              } else {

              }
            } else {
              var size = getValue("itemSelectGoodsGroupDataSize");
              if (size == 0) {
                var postDeliverType = document.getElementById("postDeliverTypeTdSurround");
                var p = document.createElement("p");
                p.textContent = "「宅配便：送料400円（税別）」よりお安くお届けできる「メール便：送料100円（税別）」をお選びいただけます。なお『通常価格』の合計が4,500円（税別）以上で送料無料です。";
                p.style.color = "red";
                p.style.margin = "5px 0 0";
                postDeliverType.appendChild(p);
              } else {
                var postDeliverType = document.getElementById("postDeliverTypeTdSurround");
                var p = document.createElement("p");
                p.textContent = "※プレミアム定額コースは、送料無料でお届けいたします。ご選択の商品によっては宅配便でのお届けとなります。";
                p.style.color = "red";
                p.style.margin = "5px 0 0";
                postDeliverType.appendChild(p);
              }
            }
          }
        }
      }
    });
  }
}

/**
 * 定期解約ページ
 * - お客様からよくいただくお悩み内オープン
 */
function openCustomerTrouble() {
  if (document.getElementById("mypageContents")) {
    $(".customer_trouble_01_link").on("click", function() {
      $(this)
        .next("#customerTrouble01")
        .slideToggle();
    });
    $(".customer_trouble_02_link").on("click", function() {
      $(this)
        .next("#customerTrouble02")
        .slideToggle();
    });
    $(".customer_trouble_03_link").on("click", function() {
      $(this)
        .next("#customerTrouble03")
        .slideToggle();
    });
  }
}

/**
 * footer電話番号押下時イベント
 * - お電話混雑状況トグル
 */
function congestion_item_sp() {
  if (document.getElementById("footerContact")) {
    $(".congestion_item_sp").on("click", function() {
      $(".congestion_item_sp_tgl").toggle();
    });
  }
}

/**
 * ID・パスワードをお持ちでないお客さま
 * - 明細書画像追加
 */
/*
function statement_img() {
  var urlParam = location.href.match("MemberExistCheck.jsp");
  if (urlParam) {
    document.addEventListener("DOMContentLoaded", function() {
      const elem = document.getElementById(
        "pagePartsHtmlText_MemberNetRegisterCheck_innerId"
      );
      var p = document.createElement("p");
      p.innerHTML =
        '※商品に同梱されている「お買い上げ明細書」の赤枠の部分に「お客さま番号」の記載がございますのでご確認ください。<br><img class="pc" style="width: 70%;margin:0 auto;" src="/assets/images_pc/mypage/meisai_PC.jpg"><img class="sp" style="width: 80%;margin:0 auto;" src="/assets/images_pc/mypage/meisai_SP.jpg"><br><br>';
      p.style.textAlign = "center";
      elem.appendChild(p);
    });
  }
}
*/

/**
 * マイページ
 * - 各種変更・確認　「確認・変更」部分にお休み追加
 */

function mypage_subscConfBtn() {
  //var urlParam1 = location.href.match("Member.jsp");
  var urlParam2 = location.href.match("RegularSchedule.jsp");
  if (document.getElementsByClassName("setting-menu-nav")) {
    document.addEventListener("DOMContentLoaded", function() {
      var target = document.querySelector("li.menu-regl span");
      var target2 = document.querySelector("li.menu-friend a");
      if (target) {
        target.innerHTML = "定期購入の<br>確認・変更・お休み";
      }
      if (target2) {
        target2.setAttribute(
          "onclick",
          "ga('send','event','my_syoukai_cp','Click','マイ紹介',1)"
        );
      }
    });
  }
  if (urlParam2) {
    document.addEventListener("DOMContentLoaded", function() {
      var target = document.querySelectorAll("a.reglOrderDetailLink");
      target.forEach(function(index) {
        index.innerText = "確認・変更・お休み";
        index.setAttribute("style", "font-size: 13px !important;");
      });
    });
  }
}

/**
 * いろとりどりの定期商品を解約する場合に一部解約のボタンとテキストを非表示にする。
 *
 */
(function() {
  document.addEventListener("DOMContentLoaded", function() {
    const param = location.href.match("RegularChangeCancel.jsp");

    if (param) {
      const cancelCode2 = document.querySelector("#cancelCode2");
      const value = cancelCode2.value;
      console.log(value);
      if (
        value === "SET2001" ||
        value === "SET2002" ||
        value === "SET2003" ||
        value === "SET2004"
      ) {
        const text = document.querySelector("#include2ItemWrap");
        const btn = document.querySelector("#cancelChangeInfomationLink_link");
        const heading = document.querySelector(
          "#RegularChangeCancel #cancelArea #cancelChangeInfomationGroup #cancelChangeInfomationLinkItemWrap h2.heading"
        );
        const disGroup = document.querySelector(
          ".set-group.cancelDisplayLayout.table.dispGroup"
        );

        text.style.display = "none";
        btn.style.display = "none";
        heading.style.cssText += "padding: 0 !important;";
        disGroup.style.marginBottom = "0";
      }
    }
  });
})();

/**
 * マイページ
 * - 定期解約
 * 下記各ページの解約ボタンのテキスト変更
 * https://www.sukkiri-life.com/RegularScheduleDetail.jsp
 * https://www.sukkiri-life.com/RegularChangeCancel.jsp
 * https://www.sukkiri-life.com/RegularCancelConfirmLife.jsp
 */
/*
(function() {
    document.addEventListener("DOMContentLoaded", function() {
        const regularScheduleDeteil_btn = document.querySelector(
            "#RegularScheduleDetail #cancel2ButtonItemWrap #cancel2Button"
        );
        const regularChangeCancel_btn = document.querySelector(
            "#RegularChangeCancel #cancelArea #cancelButtonGroup #cancelButtonGroup #cancelConfirmButtonItemWrap #cancelConfirmButton"
        );
        const regularCancelConfirmLife_btn = document.querySelector(
            "#RegularCancelConfirmLife #otherArea #buttonGroup #cancelButtonItemWrap #cancelButton"
        );

        if (regularScheduleDeteil_btn) {
            console.log(regularScheduleDeteil_btn);
            regularScheduleDeteil_btn.value = "定期の解約内容を確認する";
        } else if (regularChangeCancel_btn) {
            console.log(regularChangeCancel_btn);
            regularChangeCancel_btn.value = "定期の解約内容を確定する";
        } else if (regularCancelConfirmLife_btn) {
            console.log(regularCancelConfirmLife_btn);
            regularCancelConfirmLife_btn.value = "定期を解約する";
        }
    });
})();*/

/**
 * ページコンテンツ部分の検索窓動作
 */
function keyWordSearch(){
  const form_textBox = document.getElementById('q');
  const bodyForm_textBox1 = document.getElementById('q-2');
  const bodyForm_textBox2 = document.getElementById('q-3');

  if (bodyForm_textBox1) {
    bodyForm_textBox1.addEventListener('keyup', function () {
      form_textBox.value = bodyForm_textBox1.value;
      bodyForm_textBox2.value = bodyForm_textBox1.value;
    })
  }

  if (bodyForm_textBox2) {
    bodyForm_textBox2.addEventListener('keyup', function () {
      form_textBox.value = bodyForm_textBox2.value;
      bodyForm_textBox1.value = bodyForm_textBox2.value;
    })
  }

  if ( window.matchMedia('(max-width: 750px)').matches && bodyForm_textBox1) {
    bodyForm_textBox1.setAttribute('placeholder','商品に関するキーワードを入力')
  }
}

/**
 * 商品一覧
 * - matchHeight
 */


// マイページ 文言一時差し替え
function myPage_change_discount_text(){
  window.addEventListener('DOMContentLoaded',function (){
    let tgt_cells = document.querySelectorAll('#amountTotalGroup .th');
    // jsファイルが文字化けするのでuri encode経由
    let before_text_uri_encoded = '%E3%83%97%E3%83%AD%E3%83%A2%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E5%89%B2%E5%BC%95%E9%A1%8D';
    let after_text_uri_encoded = '%E5%89%B2%E5%BC%95%E9%A1%8D';

    tgt_cells.forEach(e=>{
      if ( e.innerText === decodeURIComponent(before_text_uri_encoded) ){
        e.innerText = decodeURIComponent(after_text_uri_encoded);
      }
    })
  })
}


import MatchHeight from "matchheight/dist/MatchHeight";
let itemInfo = document.querySelectorAll('#AllItemList #AllItemListWrap .all-item-list-container .item-list-box .item-list-info');

if (itemInfo.length > 0){
  for (let i = 0,len = itemInfo.length; i < len; i++){
    itemInfo[i].setAttribute('data-mh','');
  }
}

MatchHeight.init();
headerCosmeItems_switch();
accordionContentItem1500();
backnumberListOpen();
bulletinTabContents();
deliveryMethod();
faqScript();
globalNavFollow();
headerNavAccordion();
headerNavAccordionCategory();
headerSlideMenu();
itemSearchMenuOpen();
MemberIntroduceTabChange();
modalContentItem1500();
openCustomerTrouble();
openPriceListBeginner();
openPriceListRegularly();
openVoiceRecruitment();
orderHistoryOpen();
outputBodyMassIndex();
rankingSlider();
rankingTabSwitch();
scrollToTop();
slideMenuCartNum();
slideMenuOpen();
smoothScroll();
swiperSetting();
tabChangeContentBulletin005();
toggleCancelBox();
viewportSetting();
congestion_item_sp();
// statement_img();
mypage_subscConfBtn();
modalAction();
keyWordSearch();
beautyRecipe_accordion();
myPage_change_discount_text();