export function scrollToTop() {

  /**
   * トップまでの高さを取得
   * @returns {number}
   */
  const getScrolled = () => (window.pageYOffset !== undefined) ? window.pageYOffset : document.documentElement.scrollTop;

  /**
   * トップまで可視範囲を移動
   */
  function scrollTop() {
    const scrolled = getScrolled();
    window.scrollTo(0, Math.floor(scrolled / 1.08));
    if (scrolled > 0) window.setTimeout(scrollTop, 30);
  }

  if (document.getElementById('pageTop')) {
    const topButton = document.getElementById('pageTop');
    topButton.addEventListener('click', scrollTop, { passive: true });
  }

  if (document.getElementById('pageTopSp')) {
    const topButtonSp = document.getElementById('pageTopSp');
    topButtonSp.addEventListener('click', scrollTop, { passive: true });
  }

  if (document.getElementById('return_top')) {
    const topButton2 = document.getElementById('return_top');
    topButton2.addEventListener('click', scrollTop, { passive: true });
  }

}






